<template>
   <div class="navBox">
      <router-link to="./index"><van-button>用户列表</van-button></router-link>
      <router-link to="./deeperchainmap"><van-button>更换钱包地址</van-button></router-link>
      <router-link to="./stakingCode"><van-button>质押码</van-button></router-link>
      <router-link to="./staking"><van-button>质押列表</van-button></router-link>
      <router-link to="./sendRecord"><van-button>同步记录</van-button></router-link>
      <router-link to="./bridge"><van-button>ETH跨链</van-button></router-link>
      <router-link to="./bscbridge"><van-button>BSC跨链</van-button></router-link>
      <router-link to="./addToDeeperChain"><van-button>用户入网</van-button></router-link>
      <router-link to="./addToDeeperChainRecord"><van-button>用户入网记录</van-button></router-link>
      <router-link to="./unStakingList"><van-button>取消质押申请</van-button></router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.navBox {
  padding: 20px 30px;
  display: flex;
}
</style>